export const CHAT_LINKS = [
  {
    name: 'ChatGPT&Midjourney',
    url: 'https://chatweb.maktubcn.info/#/?settings={"key":"{key}","url":"{server}"}',
    show: true
  },
  {
    name: 'NextChat',
    url: 'https://nextchat.maktubcn.info/#/?settings={"key":"{key}","url":"{server}"}',
    show: true
  }
];
