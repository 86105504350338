// assets
import {
  IconDashboard,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconSettingsCog,
  IconKey,
  IconBuildingStore,
  IconUser,
  IconUserScan,
  IconChartHistogram,
  IconBrandTelegram,
  IconReceipt2,
  IconPhoto,
  IconNotebook,
  IconRobotFace,
  IconMessages,
  IconMicrophone
} from '@tabler/icons-react';

// constant
const icons = {
  IconDashboard,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconSettingsCog,
  IconKey,
  IconBuildingStore,
  IconUser,
  IconUserScan,
  IconChartHistogram,
  IconBrandTelegram,
  IconReceipt2,
  IconPhoto,
  IconNotebook,
  IconRobotFace,
  IconMessages,
  IconMicrophone
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const panel = {
  id: 'panel',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: '数据总览',
      type: 'item',
      url: '/panel/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false,
      isAdmin: false
    },
    {
      id: 'analytics',
      title: '图表分析',
      type: 'item',
      url: '/panel/analytics',
      icon: icons.IconChartHistogram,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'channel',
      title: '接口渠道',
      type: 'item',
      url: '/panel/channel',
      icon: icons.IconSitemap,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'playground',
      title: '对话窗口',
      type: 'item',
      url: '/panel/playground',
      icon: icons.IconMessages,
      breadcrumbs: false
    },
    {
      id: 'transcript',
      title: '同声传译',
      type: 'item',
      url: '/panel/transcript',
      icon: icons.IconMicrophone, // 修改图标
      breadcrumbs: false
    },
    {
      id: 'token',
      title: '个人令牌',
      type: 'item',
      url: '/panel/token',
      icon: icons.IconKey,
      breadcrumbs: false
    },
    {
      id: 'log',
      title: '调用日志',
      type: 'item',
      url: '/panel/log',
      icon: icons.IconArticle,
      breadcrumbs: false
    },
    {
      id: 'midjourney',
      title: '绘图历史',
      type: 'item',
      url: '/panel/midjourney',
      icon: icons.IconPhoto,
      breadcrumbs: false
    },
    {
      id: 'redemption',
      title: '兑换记录',
      type: 'item',
      url: '/panel/redemption',
      icon: icons.IconCoin,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'topup',
      title: '额度充值',
      type: 'item',
      url: '/panel/topup',
      icon: icons.IconBuildingStore,
      breadcrumbs: false
    },
    {
      id: 'user',
      title: '用户列表',
      type: 'item',
      url: '/panel/user',
      icon: icons.IconUser,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'profile',
      title: '个人设置',
      type: 'item',
      url: '/panel/profile',
      icon: icons.IconUserScan,
      breadcrumbs: false,
      isAdmin: false
    },
    {
      id: 'pricing',
      title: '价格调整',
      type: 'item',
      url: '/panel/pricing',
      icon: icons.IconReceipt2,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'model_price',
      title: '模型价格',
      type: 'item',
      url: '/panel/model_price',
      icon: icons.IconRobotFace,
      breadcrumbs: false,
      isAdmin: false
    },
    {
      id: 'introduction',
      title: '使用指南',
      type: 'item',
      url: 'https://blog.maktubcn.info/archives/1709447848670',
      icon: icons.IconNotebook,
      breadcrumbs: false,
      isAdmin: false
    },
    {
      id: 'setting',
      title: '运营设置',
      type: 'item',
      url: '/panel/setting',
      icon: icons.IconSettingsCog,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'telegram',
      title: '回复工具',
      type: 'item',
      url: '/panel/telegram',
      icon: icons.IconBrandTelegram,
      breadcrumbs: false,
      isAdmin: true
    }
  ]
};

export default panel;
